body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-background: #FAFAFA;
  --color-foreground: #1F2023;
  --color-shadow: #AAA;
  
  --image-grayscale: 0;
  --image-opacity: 100%;
}

body.dark {
  --color-background: #1F2023;
  --color-foreground: #EFEFEF;
  --color-shadow: rgba(0, 0, 0 , 0.8);

  
  --image-grayscale: 50%;
  --image-opacity: 90%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img,
video {
  filter: grayscale(var(--image-grayscale)) opacity(var(--image-opacity));
}

.react-toggle-track > * { 
  line-height: 10px !important;
  font-size: 14px;
}